/* Variables */
/* :root {
  --font-size: 16px;
} */

.acwf {
  /* background-color: rgb(240, 247, 176); */
  /* margin: 10px; */
  font-size: 16px;
}

.acwf > * {
  margin: 0 auto;
}

/* common to all "containers" */
.gen_container {
  /* border: 1px solid #999; */
  /* border-radius: 10px; */
  width: 930px;
  /* max-width: 900px; */
  margin: 0 auto;
  margin-top: 20px;
}

.graph_container {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* @media screen and (max-width: 700px) {
  :root {
    --font-size: 12px;
  }
} */
