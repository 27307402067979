.main-page {
  width: 930px;
  margin: 0 auto;
}

.main-page > * {
  margin: 0 auto;
}

.description {
  padding: 12px 12px 6px;
  text-align: justify;
  text-justify: auto;
}

.note {
  padding: 12px 12px 0px 12px;
  text-align: center;
  color: rgb(165, 10, 10);
}

.citation {
  background-color: #f5f5f5;
  border: 1px solid #d6d6d6;
  padding: 6px 12px;
  margin: 6px 20px;
  font-size: 14px;
}

/* overload boostrap tab style */
/* .nav-tabs .nav-link {
  background-color: transparent;
  color: #ff0000;
  border-top: 10px solid red;
  border-left: none;
  border-right: none;
} */

/* .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  border-bottom: 100px solid blue !important;
}

.nav-tabs {
  border-bottom: 100px solid rgb(0, 255, 0) !important;
} */

/* .nav-tabs {
  background-color: red;
} */

.nav-tabs .nav-link {
  border-top: 1px solid #ddd !important;
  border-left: 1px solid #ddd !important;
  border-right: 1px solid #ddd !important;
}

.nav-tabs .nav-link.active {
  border-top: 1px solid #999 !important;
  border-left: 1px solid #999 !important;
  border-right: 1px solid #999 !important;
  /* border-bottom: 1px solid #999 !important; */
}

.custom-bold {
  font-weight: 500;
}

.citation-link {
  color: black;
  text-decoration: underline;
}

.citation-link:hover {
  color: rgb(60, 60, 60);
}
