.help-button-outer {
  /* background-color: red; */
  height: 20px;
  width: 20px;
}

.help-button-inner {
  /* position: absolute; */
  /* top: 6px;
  right: 6px; */
  border: 1px solid #999;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  line-height: 20px;
  cursor: default; /* don't charge cursor into a bar when hovering over text */
  user-select: none; /* don't select text upon clicking */
  background: rgb(236, 236, 236);
  text-align: center;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  font-size: 14px;
}

.help-button-inner:hover {
  filter: brightness(85%);
}
