.App {
  /* margin: 0 auto; */
  /* width: 930px; */
  /* width: 400px; */
  /* display: flex;
  justify-content: center;
  align-items: center; */
  /* background-color: brown; */
}

/* bootstrap overrides */
body a {
  color: #36c;
  text-decoration: none;
}
body a:hover {
  color: #1a0dab;
  text-decoration: underline;
}
