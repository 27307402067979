.measure-selector-container {
  margin: 5px 0px;
  padding: 5px 20px 20px;
  background-color: #f2f7fc;
  border-radius: 20px;
}

.measures-ul {
  list-style: none;
  padding: 0px;
  margin: 0px;
}
.measures-ul > li {
  padding: 5px 0px 5px 15px;
}

.cbar-container {
  display: flex;
  justify-content: center;
  /* background-color: red; */
}
