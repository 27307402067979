.title-and-logo {
  width: 930px;
  /* text-align: left; */
  font-size: 24px;
  border-bottom: 1px solid #999;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title-and-doi {
  text-align: left;
}

.title-span {
  font-size: 22px;
}
.acwf-logo {
  height: 70px;
  float: right;
  vertical-align: middle;
  padding: 4px;
}
